import MainLayout from "../../layout/MainLayout";
import Team from "../../asset/image/Team.jpeg"

const AboutUs = () => {
    return (
        <MainLayout>
            <div className="container mx-auto mt-10">
                <h1 className="text-subHeadingOne">About us</h1>
                <div className="my-4">
                    {/* <p className="text-bodyTwoBold mb-1">PURPOSE</p> */}
                    <p className="text-bodyTwo">We help users grow by building their personal brands on LinkedIn</p>
                </div>
            </div>

            <div className="container mx-auto mt-10">
                <img src={Team} alt="footer-hero" className="text-center" />
            </div>

            <div className="container mx-auto mt-10">
                <h1 className="text-subHeadingOne">We have fun while working</h1>
                <div className="my-4">
                    {/* <p className="text-bodyTwoBold mb-1">PURPOSE</p> */}
                    <p className="text-bodyTwo">If you don’t have fun while working, the output is not that great. Our team brings their personality to the office and it is easier to achieve that goal when you surround yourself with fun people.</p>
                </div>
            </div>

            <div className="container mx-auto mt-10">
                <h1 className="text-subHeadingOne text-center">Our Founders</h1>
            </div>

            <div className="container mx-auto mt-10">
                <h1 className="text-subHeadingOne">Amit Singh</h1>
                <p className="text-bodyTwoBold mb-1">Co-Founder</p>

                <div className="my-4">
                    <p className="text-bodyTwo">Amit is an IIT Roorkee Alumni with a strong background in product designing with about 7 years of experience in building and scaling tech products. He has been awarded the National Tourism Award for this contribution for incorporating VR technology in promoting Indian tourism across the globe.</p>

                    <p className="text-bodyTwo">Amit jump started his career with his first startup called–OutsiteVR, a VR immersive travel startup. While Amit is an entrepreneur, he comes from a family that is highly dominated by government officers, which makes him the first in the family to run his own successful business.</p>
                </div>
            </div>

            <div className="container mx-auto mt-10">
                <h1 className="text-subHeadingOne">Kunwar Raj Sethi</h1>
                <p className="text-bodyTwoBold mb-1">Co-Founder</p>

                <div className="my-4">
                    <p className="text-bodyTwo">He loves building products, creating content, investing & playing tennis. In his first startup, he built a KYC automation product @Invoid (backed by Y Combinator) that automates more than 1 million verifications every month.</p>

                    <p className="text-bodyTwo">He also loves talking and making content on finance & investing @Unfinance. Having built a community of over 1mn people, learning about investing and finance through digital mediums. His videos have garnered more than 50 mn views.</p>
                </div>
            </div>
        </MainLayout>
    )
}
export default AboutUs;