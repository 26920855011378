export const getPlans = (tenure) => ([
    {
        name: 'Free Plan',
        description: 'For those just starting out 💪',
        amount: '₹0',
        isFreeTrail: true,
        billingPeriod:  '/month for 12 months',
        color: '#FF90F6',
        background: '#FFF4FE',
        features: [
            {
                "title": "Limited access to viral post library",
            },
            {
                title: "10 viral post search credits",
            },
            {
                title: "Write & preview posts",
            },
            {
                title: "Viral format checker",
            },
            {
                title: "2 AI Credits/ month",
            },
        ],
    },
    {
        name: 'Super Star',
        description: 'For Founders, Creators & Marketers 🌟',
        amount: tenure === 'month' ? '₹1,999' : '₹1,399',
        amountBeforeDiscount: tenure === 'month' ? '₹3,999' : '₹2,799',
        billingPeriod: tenure === 'month' ? '/month' : '/month for 12 months',
        color: '#2789EC',
        background: '#F0FAFF',
        best: true,
        features: [
            {
                "title": "Everything in free plan",
                // "description": "Enjoy unlimited searches and never run out of inspiration"
            },
            {
                "title": "Full access to viral post library",
                // "description": "Enjoy unlimited searches and never run out of inspiration"
            },
            {
                title: "200 viral post search credits",
                // description: "Browse over 8M viral posts to spark ideas and stay relevant."
            },
            {
                title: "10 AI Credits/ month ",
                // description: "Get personalized recommendations based on your impressions, engagement, and profile visits."
            },
            {
                title: "Access to 8mn+ viral posts ",
            },
            {
                title: "Latest & trending posts available",
            },
            {
                title: "Bookmark favourite Linkedin  posts ",
            },
            
            {
                title: "Comment generator (coming soon)",
            },
            
        ],
    },
    {
        name: 'Super Nova',
        description: 'For Freelancers, Agencies & Ghostwriters ✏️',
        amount: tenure === 'month' ? '₹2,999' : '₹2,099',
        amountBeforeDiscount: tenure === 'month' ? '₹5,999' : '₹4,199',
        billingPeriod: tenure === 'month' ? '/month' : '/month for 12 months',
        color: '#7145FD',
        background: '#F7F6FF',
        features: [
            {
                "title": "Everything in Super Star",
            },
            {
                title: "Unlimited viral posts search credits",
            },
            {
                title: "100 AI Credits/ month",
            },
            {
                title: "Add profiles of creators you love (coming soon)",
            },
        ],
    },
   
]);


export const getPlansInternational = (tenure) => ([
    {
        name: 'Free Plan',
        description: 'For those just starting out 💪',
        amount: '$0',
        isFreeTrail: true,
        billingPeriod:  '/month for 12 months',
        color: '#FF90F6',
        background: '#FFF4FE',
        features: [
            {
                "title": "Limited access to viral post library",
            },
            {
                title: "10 viral post search credits",
            },
            {
                title: "Write & preview posts",
            },
            {
                title: "Viral format checker",
            },
            {
                title: "2 AI Credits/ month",
            },
        ],
    },
    {
        name: 'Super Star',
        description: 'For Founders, Creators & Marketers 🌟',
        amount: tenure === 'month' ? '$29' : '$19',
        amountBeforeDiscount: tenure === 'month' ? '$59' : '$39',
        billingPeriod: tenure === 'month' ? '/month' : '/month for 12 months',
        color: '#2789EC',
        background: '#F0FAFF',
        features: [
            {
                "title": "Everything in free plan",
                // "description": "Enjoy unlimited searches and never run out of inspiration"
            },
            {
                "title": "Full access to viral post library",
                // "description": "Enjoy unlimited searches and never run out of inspiration"
            },
            {
                title: "200 viral post search credits",
                // description: "Browse over 8M viral posts to spark ideas and stay relevant."
            },
            {
                title: "10 AI Credits/ month ",
                // description: "Get personalized recommendations based on your impressions, engagement, and profile visits."
            },
            {
                title: "Access to 8mn+ viral posts ",
            },
            {
                title: "Latest & trending posts available",
            },
            {
                title: "Bookmark favourite Linkedin  posts ",
            },
            
            {
                title: "Comment generator (coming soon)",
            },
        ],
    },
    {
        name: 'Super Nova',
        description: 'For Freelancers, Agencies & Ghostwriters ✏️',
        amount: tenure === 'month' ? '$49' : '$34',
        amountBeforeDiscount: tenure === 'month' ? '$79' : '$54',
        billingPeriod: tenure === 'month' ? '/month' : '/month for 12 months',
        best: true,
        color: '#7145FD',
        background: '#F7F6FF',
        features: [
            {
                "title": "Everything in Super Star",
            },
            {
                title: "Unlimited viral posts search credits",
            },
            {
                title: "100 AI Credits/ month",
            },
            {
                title: "Add profiles of creators you love (coming soon)",
            },
        ],
    },
    
]);