import MainLayout from "../../layout/MainLayout";

const RefundPolicy = () => {
    return (
        <MainLayout>
            <div className="container mx-auto mt-10">
                <h1 className="text-subHeadingOne">Refund policy</h1>
                <div className="my-4">
                    {/* <p className="text-bodyTwoBold mb-1">PURPOSE</p> */}
                    <p className="text-bodyTwo">You acknowledge and agree that the Company is excused from performing any obligations and will not be responsible for any delays, lapses or failure where and so long as the Company is prevented from performing its obligations under these Terms by events or causes beyond its reasonable control. Company will endeavour to notify you of any delay and its expected duration. If the Company is prevented from performing its obligations for longer than a reasonable period of time, it may elect to cancel your order and issue you a refund of amount paid for the order without further liability to you. The refund will be issued to the customer’s payment account within 10 working days.</p>
                </div>
            </div>
        </MainLayout>
    )
}
export default RefundPolicy;