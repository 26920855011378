import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Home from './pages/home';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import ProductTry from './pages/product-try';
import SearchPage from './pages/product-try/searchPage';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import RecreatePost from './pages/RecreatePost';
import PrivacyPolicy from './pages/privacy-policy';
import CookiePolicy from './pages/cookie-policy';
import Terms from './pages/terms';
import Pricing from './pages/pricing';
import AboutUs from './pages/About-us';
import RefundPolicy from './pages/Refund-policy';

const queryClient = new QueryClient()

function App() {
  return (
    <div className="App bg-white">
      <QueryClientProvider client={queryClient}>

        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            {/* <Route path="/product-demo" element={<ProductTry />} />
            <Route path="/product-demo/:searchText" element={<SearchPage />} /> */}
            <Route path="/recreate-post" element={<RecreatePost />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/cookie-policy" element={<CookiePolicy />} />
            <Route path="/termsofuse" element={<Terms />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/refund-policy" element={<RefundPolicy />} />
          </Routes>
        </Router>
        <ToastContainer />
      </QueryClientProvider>
    </div>
  );
}

export default App;
