import MainLayout from "../../layout/MainLayout";

const CookiePolicy = () => {
    return (
        <MainLayout>
            <div className="container mx-auto mt-10">
                <h1 className="text-subHeadingOne text-center">Cookie Policy</h1>
                <h1 className="text-subHeadingTwo">PURPOSE</h1>

                <div className="my-4">
                    <p className="text-bodyTwo">This <b>"Cookie Policy"</b> is issued by Cozlo Technologies Private Limited (<b>"we/us/our"</b>) to the user of website (<b>"you/your"</b>). It demonstrates our privacy commitment to our users. The purpose of this policy is to communicate to the site visitor how Our https://superpen.io/, extensions or plugins thereof accessible through various internet enabled devices (individually and collectively referred to as <b>"Platform"</b>) make use of a technology called <b>“Cookies”</b>. The aim of this policy is to support the user in taking informed decisions when accessing the Platform. Please take a minute to read and understand this, Cookie Policy. This Cookie Policy should be read along-with our Privacy Policy available at https://superpen.io/privacy-policy.</p>
                </div>


            </div>

            <div className="container mx-auto mt-10">
                <h1 className="text-subHeadingTwo">WHAT ARE COOKIES?</h1>

                <div className="my-4">
                    <p className="text-bodyTwo">When a user visits an online website, a small text file that may contain a unique identifier is sent by a web server to the browser on the user's computer, mobile phone, or any other internet-enabled device. This small text file is termed as a "cookie". Cookies and other similar technologies help the websites to work efficiently and collect information about the online preferences of the user. Hereafter, these technologies may be referred to collectively as "cookies". Electronic images known as 'web beacons' (also known as clear gifs, tags, or pixels) may be present on some of our website pages that allows us to count users who have visited our pages and run analytics. Web beacons are responsible for collecting limited information e.g., a cookie number, time and date of a page view, and description of the page on which it resides. Please note that Web Beacons placed by third-party advertisers might be present on our website.</p>
                </div>
            </div>

            <div className="container mx-auto mt-10">
                <h1 className="text-subHeadingTwo">HOW ARE COOKIES USED?</h1>

                <div className="my-4">
                    <p className="text-bodyTwo">In order to customize the content and advertise, provide social media features, and to see how users move through our website, cookies, and other such technologies are used. Cookies are used to carry information from one session on a website to another, or between sessions on related websites for smooth working of website, hence, information received from cookies supports us in taking decisions about the ways to improve the services offered by us and enhance your user experience.</p>
                    <p className="text-bodyTwo mt-4">We may engage third-party service providers to act on our behalf to analyse the usage of our website by users. These third parties collect, and share with us, usage information about visits to our website and sometimes by correlating this information with other information (e.g., your IP address), measure and research the effectiveness of our advertisements, track page usage, help us target our recommendations and advertising, and track use of our recommendations for related products, suggested offers, discounts, and advertisements.</p>

                    <p className="text-bodyTwo mt-4">By default, we only collect cookies that are strictly necessary for the website to function and cannot be switched off in our systems. They are usually only set in response to actions made by you which amount to a request for services, such as setting your privacy preferences, logging in, or filling in forms.</p>
                </div>
            </div>

            <div className="container mx-auto mt-10">
                <h1 className="text-subHeadingTwo">TYPES OF COOKIES</h1>

                <div className="my-4">
                    <p className="text-bodyTwo mb-3">Below you can find out more about the purpose of each type of Cookie that We use.</p>

                    <div className="ml-4">
                        <div className="mb-3">
                            <span className="text-subHeadingThree">1. Strictly Necessary Cookies:</span>
                            <span className="text-bodyTwo ml-1">These Cookies allow us to count visits and traffic sources so we can measure and improve the performance of Our Platform. They help us to know which pages are the most and least popular among visitors.</span>
                        </div>
                        <div className="mb-3">
                            <span className="text-subHeadingThree">2. Functional Cookies:</span>
                            <span className="text-bodyTwo ml-1">These cookies enable the website to provide enhanced functionality and personalization. They may be set by us or by third-party providers whose services we have added to our pages. If you do not allow these cookies, then some or all these services may not function properly.</span>
                        </div>

                        <div className="mb-3">
                            <span className="text-subHeadingThree">3. Performance Cookies:</span>
                            <span className="text-bodyTwo ml-1">These Cookies allow us to count visits and traffic sources so we can measure and improve the performance of Our Platform. They help us to know which pages are the most and least popular among visitors.</span>
                        </div>

                        <div className="mb-3">
                            <span className="text-subHeadingThree">4. Targeting Cookies:</span>
                            <span className="text-bodyTwo ml-1">These Cookies may be set through our site by our advertising partners. They may be used by those companies to build a profile of your interests and show you relevant adverts on other sites. If you do not allow these Cookies, you will experience less targeted advertising.</span>
                        </div>

                    </div>
                </div>

                <div className="my-4">
                    <p className="text-bodyTwo mb-3">Cookies may be classified in two categories, as first-party cookies or third-party cookies:</p>

                    <div className="ml-4">
                        <div className="mb-3">
                            <span className="text-subHeadingThree">1. First-party Cookies:</span>
                            <span className="text-bodyTwo ml-1">These cookies are directly stored by the website you visit and allow website owners to collect analytics data, remember language settings, and perform other useful functions to provide a good user experience.</span>
                        </div>
                        <div className="mb-3">
                            <span className="text-subHeadingThree">2. Third-Party Cookies:</span>
                            <span className="text-bodyTwo ml-1">These cookies are used for purpose of cookie such as target advertisement, marketing, research, etc. Following are the names of such third-party companies, and their relevant cookie policy:</span>

                            <div className="my-10">
                                {/* <p className="text-center text-bodyTwo italic mb-4">FOLLOWING SECTION IS FOR INTERNAL USE AT CTPL:</p> */}
                                <table className="w-full border border-black border-collapse text-bodyTwo">
                                    <tr>
                                        <td className="border bg-slate-200 border-black p-2">Name of Third-Party</td>
                                        <td className="border bg-slate-200 border-black p-2">Cookie Policy Link</td>
                                        <td className="border bg-slate-200 border-black p-2">Purpose</td>
                                    </tr>
                                    <tr>
                                        <td className="border border-black p-2">Google</td>
                                        <td className="border border-black p-2"><a href="https://policies.google.com/technologies/cookies?hl=en-US" target="_blank">https://policies.google.com/technologies/cookies?hl=en-US</a></td>
                                        <td className="border border-black p-2">Save log-in </td>
                                    </tr>
                                    <tr>
                                        <td className="border border-black p-2">LinkedIn</td>
                                        <td className="border border-black p-2"><a href="https://www.linkedin.com/legal/cookie-policy" target="_blank">https://www.linkedin.com/legal/cookie-policy</a></td>
                                        <td className="border border-black p-2">Post to LinkedIn </td>
                                    </tr>
                                    <tr>
                                        <td className="border border-black p-2">Razorpay</td>
                                        <td className="border border-black p-2"><a href="https://razorpay.com/privacy/" target="_blank">https://razorpay.com/privacy/</a>
                                        </td>
                                        <td className="border border-black p-2">Collect payments
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container mx-auto mt-10">
                <h1 className="text-subHeadingTwo">MANAGING COOKIES </h1>

                <div className="my-4">
                    <p className="text-bodyTwo">Your personal data (as defined in our Privacy Policy) will be collected via cookies, and other similar tracking technology, with prior consent from you. We will maintain a record of the types of cookies that you have authorized us to use and ensure adherence to your preferences. If you do not wish to accept cookies from Our Platform, you can leave Our Platform or configure your browser to either notify you when you receive a cookie (giving you the chance to decide whether to accept it) or to block all or certain categories of cookies. The browser can also delete previously stored cookies. Please see your browser’s help facility for information on setting how cookies are handled and managing cookie preferences. </p>
                </div>
            </div>

            <div className="container mx-auto mt-10">
                <h1 className="text-subHeadingTwo">YOUR OPTIONS</h1>

                <div className="my-4">
                    <p className="text-bodyTwo">You can block, delete or disable cookies on your browser whenever you want by following the instructions provided in the links below. Please note that by deleting cookies or disabling future cookies you may not be able to access certain areas or features of Our Platform such as product catalogues, payment gateways, etc.</p>
                </div>
                <div className="my-4">
                    <p className="text-bodyTwo">You can block, delete or disable cookies on your browser whenever you want by following the instructions provided in the links below. Please note that by deleting cookies or disabling future cookies you may not be able to access certain areas or features of Our Platform such as product catalogues, payment gateways, etc.</p>

                    <div className="my-10">
                        {/* <p className="text-center text-bodyTwo italic mb-4">FOLLOWING SECTION IS FOR INTERNAL USE AT CTPL:</p> */}
                        <table className="w-full border border-black border-collapse text-bodyTwo">
                            <tr>
                                <td className="border bg-slate-200 border-black p-2">Explorer</td>
                                <td className="border border-black p-2"><a href="http://windows.microsoft.com/en-gb/internet-explorer/delete-manage-cookies#ie=ie-11" target="_blank">http://windows.microsoft.com/en-gb/internet-explorer/delete-manage-cookies#ie=ie-11</a></td>
                            </tr>
                            <tr>
                                <td className="border bg-slate-200 border-black p-2">Firefox</td>
                                <td className="border border-black p-2"><a href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences" target="_blank">https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences</a></td>
                            </tr>
                            <tr>
                                <td className="border bg-slate-200 border-black p-2">Chrome</td>
                                <td className="border border-black p-2"><a href="https://support.google.com/chrome/answer/95647?hl=en" target="_blank">https://support.google.com/chrome/answer/95647?hl=en</a></td>

                            </tr>
                            <tr>
                                <td className="border bg-slate-200 border-black p-2">Safari</td>
                                <td className="border border-black p-2"><a href="https://support.apple.com/kb/PH19214?locale=enGB" target="_blank">https://support.apple.com/kb/PH19214?locale=enGB</a>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>

            <div className="container mx-auto mt-10">
                <h1 className="text-subHeadingTwo">REFRESH SCHEDULE</h1>
                <div className="my-4">
                    <p className="text-bodyTwo">This Cookie Policy, along with all policies and referenced documentation identified in this document shall be subject to review and possible revision annually or upon request by Our Management and concerned Head of Department(s).</p>
                </div>
            </div>

            <div className="container mx-auto mt-10">
                <h1 className="text-subHeadingTwo">QUESTIONS, COMMENTS, AND INFORMATION</h1>
                <div className="my-4">
                    <p className="text-bodyTwo">If you have any questions about the Our Cookie Policy, do send us an email at hello@superpen.io.</p>
                </div>
            </div>

            <div className="container mx-auto mt-10">
                <h1 className="text-subHeadingTwo">REVIEW AND AMENDMENTS TO THIS POLICY</h1>
                <div className="my-4">
                    <p className="text-bodyTwo">The Cookie Policy is subject to reviews from time to time (at least once in a year), or in case of a change in the business environment or a change in the applicable regulations and legislations. If we make any changes to this policy, we will post those changes at the top of this page or notify you by other means such as email, etc.</p>
                </div>
                <div className="my-4">
                    <p className="text-bodyTwo">If You have any concern related to this Cookie Policy or collection, storage, retention or disclosure of Cookies under the terms of this Cookie Policy or Terms of Use or any other terms and conditions of provided by Us including any queries or grievances, You can contact Us at the below given details:</p>
                </div>

                <ul className="ml-4">
                    <li className="text-bodyTwo">• Name: Cozlo Technologies Private Limited</li>
                    <li className="text-bodyTwo">• Address: 17A, M3, DLF phase 2, Gurgaon, India- 122002</li>
                    <li className="text-bodyTwo">• E-mail: amit@unfinance.co</li>
                    <li className="text-bodyTwo">• Telephone Number: +91 7042499302</li>
                    <li className="text-bodyTwo">• Working Days: Monday to Friday</li>
                    <li className="text-bodyTwo">• Working Hours: 10am-7pm</li>
                </ul>

                <div className="my-4">
                    <p className="text-bodyTwo">We will strive to address Your feedback and concerns in a timely and effective manner.</p>
                </div>

            </div>

            {/* <div className="container mx-auto mt-10">
                <h1 className="text-subHeadingOne text-center">SAMPLE COOKIE CONSENT BANNER AND CHECKLIST</h1>

                <div className="my-4">
                    <p className="text-bodyTwo">The following is an indicative banner, which can be displayed on CTPL’s Platform:</p>
                </div>

                <div className="my-4">
                    <p className="text-bodyTwo">“We use Cookies for providing a seamless experience to the visitors of our website, to personalize content and ads, to analyse traffic, and to deliver relevant and valuable content to you. We also share information about your use of our site with our social media, advertising, and analytics partners who may combine it with other information that you have provided to them or that they have collected from your use of their services. The cookie information may not directly identify you, but it can give you a more personalized website experience. Because we respect your right to privacy, you can choose not to allow some types of cookies. Click on the different category headings to find out more and change our default settings. However, blocking some types of cookies may impact your experience of the site and the services we are able to offer.</p>
                </div>

                <div className="my-4">
                    <p className="text-bodyTwo">Please provide consent by ticking on the boxes given below for each type of cookie that can be used to store information about you:</p>
                </div>

                <ul className="ml-4">
                    <li className="text-bodyTwo">• Strictly necessary cookies</li>
                    <li className="text-bodyTwo">• Functional cookies</li>
                    <li className="text-bodyTwo">• Performance cookies</li>
                    <li className="text-bodyTwo">• Targeting cookies</li>
                </ul>


            </div>


            <div className="container mx-auto mt-10">
                <div className="my-4">
                    <p className="text-bodyTwo text-center">FOLLOWING SECTION IS FOR INTERNAL USE AT CTPL:</p>
                </div>
                <div className="my-4">

                    <div className="my-10">
                        <table className="w-full border border-black border-collapse text-bodyTwo">
                            <tr>
                                <td className="border border-black p-2">#</td>
                                <td className="border border-black p-2">Type of Information </td>
                                <td className="border border-black p-2">Document Data </td>
                            </tr>
                            <tr>
                                <td className="border border-black p-2">1.</td>
                                <td className="border border-black p-2">Document Title</td>
                                <td className="border border-black p-2">Cookie Policy</td>
                            </tr>
                            <tr>
                                <td className="border border-black p-2">2.</td>
                                <td className="border border-black p-2">Date of Release</td>
                                <td className="border border-black p-2">04/11/24</td>
                            </tr>
                            <tr>
                                <td className="border border-black p-2">3.</td>
                                <td className="border border-black p-2">Document Owner</td>
                                <td className="border border-black p-2">Document Data </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div> */}



        </MainLayout>
    )
}
export default CookiePolicy;