import { Bold, Emoji, Italic, Underline } from 'iconoir-react';

const buttons = [
    { label: 'Bold', icon: <Bold width={24} height={24} color="#A489F3" />, style: 'BOLD' },
    { label: 'Italic', icon: <Italic width={24} height={24} color="#A489F3" />, style: 'ITALIC' },
    { label: 'Underline', icon: <Underline width={24} height={24} color="#A489F3" />, style: 'UNDERLINE' },
    { label: 'Emoji', icon: <Emoji width={24} height={24} color="#A489F3" />, style: 'EMOJI' },
];

const EditorToolbar = ({ applyStyle, showEmojiPicker, setShowEmojiPicker, isStyleActive }) => (
    <div className="flex gap-2">
        {buttons.map(({ label, icon, style }) => (
            <button
                key={style}
                onMouseDown={(e) => {
                    e.preventDefault();
                    if (style !== 'EMOJI') {
                        applyStyle(style);
                    } else {
                        setShowEmojiPicker(!showEmojiPicker);
                    }
                }}
                className={`w-8 h-8 rounded-full flex items-center justify-center hover:bg-star-light2 ${isStyleActive(style) ? 'bg-star-light2' : ''}`}
                title={label}
            >
                {icon}
            </button>
        ))}
    </div>
);

export default EditorToolbar;
