import { useEffect, useState } from "react";
import SegmentedBtn from "../../component/SegmentedBtn";
import PlanCard from "../../component/PlanCard";
import { getPlans, getPlansInternational } from "../../constent/plan";
import { getLocationService } from "../../service/ip.service";
import { useQuery } from "@tanstack/react-query";

const HomePlan = () => {

    const { data, isLoading } = useQuery({
        queryKey: ['ip-detail'],
        queryFn: getLocationService,
        staleTime: Infinity,
        retry: false
    });

    const currency = data?.countryCode === 'IN' ? 'INR' : 'USD';

    const [activeIndex, setActiveIndex] = useState(0);
    const currentPlanList = currency === 'INR' ? getPlans(activeIndex === 1 ? 'month' : 'year') : getPlansInternational(activeIndex === 1 ? 'month' : 'year');
    return (
        <div className="container mx-auto mb-5 md:mb-[70px]">
            <p className="text-headingThree md:text-headingTwo text-center"><span className="text-star-default">Save 50% </span>for 12 months</p>
            <p className="text-bodyOne md:text-subHeadingThree text-gray-5 text-center">Limited Period Offer</p>
            <div className="flex justify-center mt-4 mb-10 md:mb-20">
                <SegmentedBtn list={['Yearly (Save 30%)', 'Monthly']} activeIndex={activeIndex} onChange={setActiveIndex} />
            </div>
            <div className="flex flex-wrap md:flex-nowrap justify-center  gap-5">
                {
                    currentPlanList.map((item, index) => (
                        <PlanCard key={index} {...item} />
                    ))
                }
            </div>
        </div>
    )
}
export default HomePlan;