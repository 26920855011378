// import { Menu } from "iconoir-react";
import { ArrowRight, Menu, Sparks } from "iconoir-react";
import LogoIcon from "../asset/svg/LogoIcon";
import { links } from "../constent/links";
import useResponsive from "../hook/useResponsive";

import MobileSidebar from "./MobileSidebar";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";

const Header = () => {
    const { isMobile } = useResponsive();
    const [openSideBar, setOpenSideBar] = useState(false);
    const { pathname } = useLocation();

    return (
        <div className="flex sticky top-0 justify-between items-center p-3 md:py-3 md:px-8 bg-white shadow-default z-50">
            <Link to={'/'}>
                <LogoIcon width={isMobile ? '101' : undefined} />
            </Link>
            <div className="hidden md:flex items-center gap-[14px]">
                <Link to={'/pricing'} className={`nav-link ${pathname.includes('/pricing') ? 'active':''}`}>
                    Pricing
                </Link>
                <a href={links.login} target="_blank" rel="noreferrer" className="outlined-btn">Log in</a>
                <a href={links.sigup} target="_blank" rel="noreferrer" className="filled-btn"> <Sparks /> Start for free <ArrowRight /></a>
            </div>
            <div className="md:hidden flex items-center justify-center cursor-pointer" onClick={() => {
                setOpenSideBar(true);
            }}>
                <Menu width={30} height={30} />
            </div>
            {
                openSideBar && isMobile && <MobileSidebar onClose={() => {
                    setOpenSideBar(false)
                }} />
            }
            <div className="md:hidden fixed left-0 bottom-0 w-full z-50 bg-star-light2 shadow-default flex justify-between items-center p-2 gap-3">
                <a href={links.login} target="_blank" rel="noreferrer" className="outlined-btn bg-white small">Log in</a>
                <a href={links.sigup} target="_blank" rel="noreferrer" className="filled-btn flex-1 small"> <Sparks /> Try for FREE</a>
            </div>
        </div>
    )
}
export default Header;