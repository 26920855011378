import MainLayout from "../../layout/MainLayout";

const PrivacyPolicy = () => {
    return (
        <MainLayout>
            <div className="container mx-auto mt-10">
                <h1 className="text-subHeadingOne">Privacy Policy</h1>
                <div className="my-4">
                    <p className="text-bodyTwoBold mb-1">PURPOSE</p>
                    <p className="text-bodyTwo">This "Privacy Policy" is issued by Cozlo Technologies Private Limited ("we/us/our") to the user of website ("you/your"). We are concerned about the privacy of the Data and Information (for the purpose of this Privacy Policy, the term "Data" and "Information" are used synonymously) of the users accessing and availing services provided on our websites at https://superpen.io/, extensions or plugins thereof accessible through various internet enabled devices (individually and collectively referred to as "Platform") or otherwise doing business with us.</p>
                </div>
                <div className="my-4">
                    <p className="text-bodyTwoBold mb-1">SCOPE</p>
                    <p className="text-bodyTwo">
                        This Privacy Policy is issued in accordance with the provisions of the applicable laws, including but not limited to the Digital Personal Data Protection Act, 2023 ("Data Protection Legislation"), Information Technology Act, 2000, the Information Technology (Intermediary Guidelines and Digital Media Ethics Code), Rules, 2021. For avoidance of doubt, 'Data Fiduciary', 'Data Processing', and 'Personal Data' shall have the meaning ascribed to them under the Data Protection Legislation.
                        <div className="h-[10px]" />
                        This Privacy Policy establishes an effective, accountable and transparent framework for ensuring compliance with the requirements of the Data Protection Legislation. This policy applies to all of Our employees, Our service providers and all third parties responsible for the processing of Personal Data on behalf of Our services.
                        <div className="h-[10px]" />
                        This Privacy Policy applies to Us and helps you understand how we collect, use, store, process, transfer, share and otherwise deal with and protect all Your information when you visit any of Our Platform (s).
                        <div className="h-[10px]" />
                        THIS PRIVACY POLICY IS AN ELECTRONIC RECORD IN THE FORM OF ELECTRONIC CONTRACT IN TERMS OF INDIAN INFORMATION TECHNOLOGY ACT, 2000 AND RULES MADE THEREUNDER (AS AMENDED FROM TIME TO TIME) AND DOES NOT REQUIRE ANY PHYSICAL SIGNATURE OR SEAL
                    </p>
                </div>
                <div className="mt-10">
                    <h2 className="text-center text-subHeadingTwoBold my-6">ACKNOWLEDGMENT</h2>
                    <p className="text-bodyTwo">
                        PLEASE READ THIS PRIVACY POLICY CAREFULLY. YOU INDICATE THAT YOU UNDERSTAND, AGREE AND CONSENT TO THIS PRIVACY POLICY. YOU HEREBY GIVE YOUR UNCONDITIONAL, SPECIFIC AND UNAMBIGUOUS CONSENT OR AGREEMENT TO COZLO TECHNOLOGIES PRIVATE LIMITED AS REQUIRED UNDER THE DATA PROTECTION LEGISLATION FOR COLLECTION, USE, STORAGE, PROCESSING, SHARING AND TRANSFER AND DISCLOSURE OF YOUR PERSONAL DATA.
                        <div className="h-[10px]" />
                        YOU ACKNOWLEDGE THAT YOU HAVE ALL LEGAL RIGHTS AND LAWFUL AUTHORITY TO SHARE THE PERSONAL DATA WITH US AND FURTHER ACKNOWLEDGE THAT BY COLLECTING, SHARING, PROCESSING AND TRANSFERRING PERSONAL DATA PROVIDED BY YOU, YOU SHALL NOT CAUSE ANY LOSS OR WRONFUL GAIN TO YOU OR ANY OTHER PERSON.
                        <div className="h-[10px]" />
                        You hereby acknowledge that You have not provided Your consent to this Privacy Policy under the influence of someone, under force, duress or under any other condition.
                    </p>
                </div>

                <div className="my-4">
                    <p className="text-bodyTwoBold mb-1">TOPICS COVERED:</p>
                    <p className="text-bodyTwo">This Privacy Policy is designed to make You understand:</p>
                    <ul className="text-bodyTwo list-disc pl-6">
                        <li>The type of Personal Data which You share with Us or which We collect during Your usage of our Platform or availing any Services from Our Platform.</li>
                        <li>The purpose of collection, storage, Processing and transferring of Your Personal Data by Us.</li>
                        <li>Security measures implemented by Us to protect Your Personal Data as mandated by law.</li>
                        <li>Disclosure, sharing and transfer of Your Personal Data by Us and purpose of such disclosure, sharing or transfer.</li>
                    </ul>
                    <p className="text-bodyTwo">
                        Personal Data may be collected through various ways, including but not limited to the use of cookies. We may store temporary or permanent "cookies" on Your computer. "Cookies" are files situated on Your mobile/ computer/devices hard disk that assist Us in providing services. Such use of Cookies shall be in accordance with the "Cookie Policy" available at https://superpen.io/cookie-policy. Further, there are certain features of the Website that are available only through the use of a "cookie".
                        <div className="h-[10px]" />
                        You can erase or choose to block these cookies from Your computer. You can configure Your computer's div className="h-[10px]"owser to alert You when We attempt to send You a cookie with an option to accept or refuse the cookie. If You have turned cookies off, you may be prevented from using certain features of the Website.
                        <div className="h-[10px]" />
                        Your Personal Data will mostly be stored in electronic form however certain Data may be stored in physical form.
                    </p>
                </div>


                <div className="my-4">
                    <p className="text-bodyTwoBold mb-1">Data Fiduciary's Responsibilities</p>
                    <p className="text-bodyTwo">The Data Fiduciary is responsible for the collection, use, disclosure, retention, and protection of Your Personal Data in accordance with its privacy standards and the Data Protection Legislation.</p>
                </div>

                <div className="my-4">
                    <p className="text-bodyTwoBold mb-1">Personal Data We Collect (Your Data):</p>
                    <p className="text-bodyTwo">We collect Your Personal Data during Your usage of Platform or when You avail any services available on the Platform, either as a registered user or otherwise when you visit any website pages hosted by Us or other mobile applications hosted by Us. </p>

                    <p className="text-bodyTwo">The Personal Data collected may consist of:</p>
                    <ol className="text-bodyTwo pl-10 list-decimal" >
                        <li>Your Personal Data:</li>
                        <p>Your full name, email address, phone number, current employment status (whether You are a student/professional/founder/freelancer). We also collect Your Personal Data when You create an account on Our Platform or fill out a form or respond to a survey conducted by Us. We shall seek Your explicit consent for collection, storage, processing and transferring Your Personal Data to third party entities. </p>
                        <li>Other Personal Data:
                            <ol className="pl-10" style={{ listStyleType: 'lower-roman' }}>
                                <li>Usage: How You use Our Platform, such as the types of content that You view or engage with, the features You use, Your searches & results, Your browsing information, the actions You take and the time, frequency and duration of Your activities.</li>
                                <li>Device Information: Information about the computer, mobile, laptop, tablet or any other internet enabled electronic device (“Device”) You use to access the Platform. This may include real - time information about the geographic location of Your Device (such processing shall only be based on the permission granted to Us by You, internet connection, Your IP address, operating systems, platforms, browsing information, Device type, Device ID, network information, metadata and other information associated with other files stored on Your Device, last URL visited, Your website search history.</li>
                                <li>Preference: Your preferences will be altered based on the Cookie Policy and setting such as geographical location, time zone and language will also be recorded.</li>
                                <li>Information from third parties: We may collect, process and store Your user ID associated with any social media account (such as Your LinkedIn account) that You use to sign into the Platform or connect with or use with the services offered by Us. When You sign in to Your account with Your social media account information, or otherwise connect to Your social media account with the Services, We shall seek Your consent to collect, store, and use Your Personal Data, in accordance with this Privacy Policy, based on the information that You make available to Us through the social media interface. This could include, without limitation, any information that You have made public through Your social media account, information that the social media service shares with Us, or information that is disclosed during the sign-in process by You. We urge You to refer to Your social media provider's privacy policy and help center for more information regarding use and processing of Your Personal Data by them.</li>
                                <li>Cookies and other Electronic Tools: We may use cookies, pixel tags, web beacons, mobile device IDs, 'flash cookies' and similar files or technologies to collect and store information in respect to Your use of the Platform. You can erase or choose to disable / block these cookies through Your Device / browser settings. You can configure Your browser to alert You when we attempt to send You a cookie with an option to accept or refuse the cookie. If You have turned cookies off, You may be prevented from using certain features of the Platform and this may interfere with certain functionality of the Platform.</li>
                                <li>Browser Add-on/Extension: Add-on or Extensions are software programs that can modify and/or enhance the functionality of browsers. You can add extensions to Your browser (Chrome, Safari, Firefox etc.) for a more personalised experience. Add - on / Extension can access Your Personal Data on all the websites You visit, the tabs and the browsing activity of the browser. Add - on / Extension is stored locally to provide a better user experience for You. </li>
                            </ol>
                        </li>
                    </ol>
                </div>

                <div className="my-4">
                    <p className="text-bodyTwoBold mb-1">Legal Basis for Processing Your Personal Data</p>
                    <p className="text-bodyTwo">
                        We process Your Personal Data based on consent. Such consent shall be recorded based on Your acceptance of this Privacy Policy. Your consent is revocable at any time.
                    </p>
                </div>
                <div className="my-4">
                    <p className="text-bodyTwoBold mb-1">Linked Websites</p>
                    <p className="text-bodyTwo">
                        You may access third-party websites, portals, applications, PWAs or such services through Our Platform, however, We are not in any manner liable or responsible for the privacy statements, practices, or the contents of such third-party websites, portals, applications, PWAs or such services.
                    </p>
                </div>
                <div className="my-4">
                    <p className="text-bodyTwoBold mb-1">Consent to Communication</p>
                    <ol className="text-bodyTwo list-decimal pl-10">
                        <li>You hereby consent to being contacted by Us, through email, mobile phone, notices, alerts, text messages, push notifications, or any other such legally permitted mode. </li>
                        <li>2.You further consent to being contacted with regard to availability of our services, promotional offers, marketing communication or other such services related issues. You may change Your communication preferences at any time.</li>
                    </ol>
                    <p className="text-bodyTwo">
                        Your consent is revocable at any time.
                    </p>
                </div>
                <div className="my-4">
                    <p className="text-bodyTwoBold mb-1">Manner of storage of Your Personal Data</p>

                    <p className="text-bodyTwo">
                        Your Personal Data will be stored in electronic form however certain Personal Data may be stored in physical form. We shall store, collect, use and process Your Personal Data within Republic of India subject to compliance under applicable laws. We may enter into agreements with third parties within India to store or process Your Personal Data and such third parties may have their own security measures to safeguard Your Personal Data which security standards as comparable with good industry practices.
                    </p>
                </div>

                <div className="my-4">
                    <p className="text-bodyTwoBold mb-1">Purpose of Collection, Storage, Processing of Your Personal Data</p>
                    <p className="text-bodyTwo">We collect Your Personal Data for the following purpose</p>
                    <ol className="text-bodyTwo list-decimal pl-10">
                        <li>To provide the services to You, through Our Platform; </li>
                        <li>To facilitate Your usage of Platform and improve Our services, or any other content on the Platform (such as by personalizing content to your interests), process and complete Your transactions, and make special offers; </li>
                        <li>To review Platform performance, diagnose technical problems and do Data analysis to make Your experience better; </li>
                        <li>To protect the integrity of Our Platform;</li>
                        <li>To inform You about change in any term and condition of this Privacy Policy or Terms of Use of the Platform;</li>
                        <li>To implement information security practices, to determine any security breaches, contaminant or computer virus, to investigate / prevent / take action against illegal activities and suspected fraud;</li>
                        <li>To keep You informed about news, events and ideas regarding Your preferences and help You to improve Your knowledge and skills about the preferred content;</li>
                        <li>To carry out Our obligations and enforce Our rights arising from any contracts entered into between You and Us;</li>
                        <li>To allow You to participate in interactive features offered through Our Platform;</li>
                        <li>To contact You about Our services that may be of interest to You. If You do not want Us to use Your Personal Data in this way, please adjust Your user preferences in Your account profile;</li>
                        <li>To produce and share aggregate insights and statistics that do not personally identify You.</li>
                    </ol>

                </div>
                <div className="my-4">
                    <p className="text-bodyTwoBold mb-1">Data Retention</p>
                    <p className="text-bodyTwo">
                        To ensure fair processing, Personal Data will not be retained by Us for longer than necessary in relation to the purposes for which it was originally collected, or for which it was further processed.
                        <br />
                        The length of time for which We need to retain Personal Data is in accordance with the applicable laws.
                    </p>
                </div>
                <div className="my-4">
                    <p className="text-bodyTwoBold mb-1">Transfer of Personal Data</p>
                    <p className="text-bodyTwo">
                        We may transfer Personal Data to internal or third-party recipients located in another country where that country is recognised as having an adequate level of legal protection for the rights and freedoms of the relevant Data Prinicpals. Where transfers need to be made to countries lacking an adequate level of legal protection (i.e. third countries), they must be made in compliance with an approved transfer mechanism. Any transfer by Us, will be based on Your consent.
                    </p>
                </div>
                <div className="my-4">
                    <p className="text-bodyTwoBold mb-1">Disclosure of Personal Data</p>
                    <p className="text-bodyTwo">
                        If required, We may disclose Your Personal Data to external law enforcement bodies or regulatory authorities, in order to comply with legal obligations to which We or our service providers are subject to.
                    </p>
                </div>
                <div className="my-4">
                    <p className="text-bodyTwoBold mb-1">Children's Data</p>
                    <p className="text-bodyTwo">
                        We do not knowingly solicit or collect Personal Data from children i.e., individuals below the age of 18 years without obtaining verifiable parental consent. If it is revealed to us, that a child under the age of 18 years has submitted their Personal Data without parental consent, We take all reasonable measures to delete such Personal Data from Our Database and to not use such Personal Data for any purpose (except where necessary to protect the safety of the child or others as required or permitted under applicable law). If You are aware of any Personal Data that We have collected from an individual below the age of 18 years, please contact Us at hello@superpen.io.
                    </p>
                </div>
                <div className="my-4">
                    <p className="text-bodyTwoBold mb-1">Security Measures and Force Majeure</p>
                    <p className="text-bodyTwo">
                        We take utmost care to secure Your Personal Data from unauthorised access, usage or disclosure or alteration. We take appropriate steps and security measures to safeguard Your Personal Data and make sure that Your Personal Data is secured as mandated under the Data Protection Legislation. For this purpose We adopt reasonable security practices and procedures, in line with the industry standard, to include, technical, operational, managerial and physical security controls in order to protect Your Personal Data from unauthorized access, or disclosure while it is under Our control.
                        <div className="h-[10px]" />
                        While We protect Your Personal Data as per industry standards, You acknowledge that the internet or computer networks are not fully secure and that We cannot provide any absolute assurance regarding the security of Your Personal Data. Therefore, You acknowledge that You shall not hold Us responsible in any manner whatsoever for loss of Your Personal Data, or any other data, which You share with Us or which We have in Our possession if such loss or any damage caused to You because of Force Majeure events.
                        <div className="h-[10px]" />

                        It is clarified that Force Majeure events includes any event which is beyond Our reasonable control which may include but not limited to sabotage, fire, flood, explosion, acts of God, civil commotion, strikes or industrial action of any kind, riots, insurrection, war, acts of government.
                    </p>
                </div>

                <div className="my-4">
                    <p className="text-bodyTwoBold mb-1">Data Principal's Rights Change or Correction of Personal Data</p>
                    <p className="text-bodyTwo">
                        We take all endeavours to update Our records with latest Information as provided by You, however if You see any discrepancy in Your Personal Data being processed, You may edit Your Personal Data or contact Us through Our Data Protection Officer / Grievance Officer to have Your Personal Data updated with Us.
                    </p>
                </div>
                <div className="my-4">
                    <p className="text-bodyTwoBold mb-1">Object to, or Limit or Restrict, Use</p>
                    <p className="text-bodyTwo">
                        You can ask Us to stop using or to limit Our use (in partial or full) of Your Personal Data (past, existing or future).
                    </p>
                    <ul className="text-bodyTwo list-disc pl-10">
                        <li>Deletion: You can ask Us to erase or delete (in partial or full) Your Personal Data (past, existing or future) provided that such deletion shall be subject to applicable regulatory/legal requirements </li>
                        <li>Right to Access: You can ask Us for a copy of Your Personal Data. </li>
                        <li>Account Closure: If You choose to close Your Account, We will delete Your Personal Data or de-identify it so that it is anonymous and not attributed to Your identity We will retain Your Personal Data after You have closed Your account if reasonably necessary to comply with Our legal obligations including law enforcement requests), meet regulatory requirements, resolve disputes, maintain security, prevent fraud and abuse, enforce Our User Agreement, or fulfil Your request to "unsubscribe" from further messages from Us. We will retain de - personalized information after Your account has been closed.</li>
                        <li>Withdraw Consent/Opt-out: At any time, you can withdraw consent that You have provided to Us by going to Your account settings, if you have an account with Us or otherwise You may request for Withdrawing Your consent by writing an email to Us at hello@superpen.io. We will only collect and process Personal Data about You where We have lawful basis. Lawful basis includes consent (where You have given consent),) and/ or "legitimate uses" as specified under the Data Protection Legislation. Where We rely on Your consent to process Personal Data, You have the right to withdraw or decline Your consent at any time and where We rely on legitimate interests, You have the right to object.</li>

                    </ul>
                    <p className="text-bodyTwo">
                        You may also contact Us using the contact information below, and We will consider Your request in accordance with applicable laws.
                    </p>
                </div>

                <div className="my-4">
                    <p className="text-bodyTwoBold mb-1">Change in terms of Privacy Policy:</p>
                    <p className="text-bodyTwo">
                        We reserve the right to amend or modify this Privacy Policy at any time, as and when the need arises. We request You to regularly check this Privacy Policy from time to time to keep You apprised of changes made. Your continued use of the Platform gives Your unconditional acceptance to such change in terms of Privacy Policy.
                        <div className="h-[10px]" />
                        If You have any concern related to this Privacy Policy or collection, storage, retention or disclosure of Your Personal Data under the terms of this Privacy Policy or Terms of Use or any other terms and conditions of provided by Us including any queries or grievances, You can contact Us through Our Data Protection Officer / Grievance Redressal Officer at the below given details:
                    </p>
                    <ul className="text-bodyTwo list-disc pl-10">
                        <li>Name: Cozlo Technologies Private Limited</li>
                        <li>Address: 17A, M3, DLF phase 2, Gurgaon, India- 122002 </li>
                        <li>E-mail: Hello@superpen.io</li>
                        <li>Telephone Number: +91 7042499302</li>
                        <li>Working Days: Monday to Friday</li>
                        <li>Working Hours: 10am-7pm</li>
                    </ul>
                    <p className="text-bodyTwo">
                        We will strive to address Your feedback and concerns in a timely and effective manner. Please note that the details of the Data Protection Officer / Grievance Officer may be changed by Us from time to time by updating this Privacy Policy. We may change or update Our data privacy practices and hence, update this Privacy Policy, such updated terms will be made available on Our Platform.
                    </p>
                </div>
                <div className="my-10">
                    <p className="text-center text-bodyTwo italic mb-4">FOLLOWING SECTION IS FOR INTERNAL USE AT CTPL:</p>
                    <table className="w-full border border-black border-collapse text-bodyTwo">
                        <tr>
                            <td className="border border-black p-2">#</td>
                            <td className="border border-black p-2">Type of Information</td>
                            <td className="border border-black p-2">Document Data</td>
                        </tr>
                        <tr>
                            <td className="border border-black p-2">1.</td>
                            <td className="border border-black p-2">Document Title </td>
                            <td className="border border-black p-2">Cookie Policy </td>
                        </tr>
                        <tr>
                            <td className="border border-black p-2">2.</td>
                            <td className="border border-black p-2">Date of Release</td>
                            <td className="border border-black p-2">04/11/24</td>
                        </tr>
                        <tr>
                            <td className="border border-black p-2">3.</td>
                            <td className="border border-black p-2">Document Revision No. </td>
                            <td className="border border-black p-2">1</td>
                        </tr>
                        <tr>
                            <td className="border border-black p-2">4.</td>
                            <td className="border border-black p-2">Document Owner</td>
                            <td className="border border-black p-2">SuperPen</td>
                        </tr>
                    </table>

                </div>
            </div>
        </MainLayout>
    )
}
export default PrivacyPolicy;