import axios from "axios";

export const getLocationService = async () => {
    try {
        const response = await axios.get('https://ipapi.co/json/');
        // console.log(response)
        return {
            ip: response.data.ip,
            country: response.data.country_name,
            countryCode: response.data.country_code,
            city: response.data.city,
            countryCallingCode: response.data.country_calling_code,
            currency: response.data.currency,
            currencyName: response.data.currency_name
        };
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Unable to fetch location');
    }
};
