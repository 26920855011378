// import LinkedinIcon from "../../asset/svg/LinkedinIcon";
import WebsiteScreenShotImage from '../../asset/image/website-screenshot.png';
import VideoPlaceHolderImage from '../../asset/image/VideoPlaceholder.png';
// import useResponsive from "../../hook/useResponsive";
import GroupAvatarImage from '../../asset/image/group-avatar.png';
import { links } from "../../constent/links";
import LinkedinIconWithText from '../../asset/svg/LinkedinIconWithText';
import { useRef, useState } from 'react';

const LogoIcon = () => {
    return (
        <svg className='w-[80%] md:w-[640px]' viewBox="0 0 640 206" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.65" filter="url(#filter0_f_38_8008)">
                <ellipse cx="319.893" cy="103.246" rx="260.033" ry="43.1743" fill="#5F38DB" />
            </g>
            <path d="M234.226 127.35C230.856 127.383 227.515 126.71 224.411 125.374C221.475 124.109 218.831 122.233 216.649 119.865C214.485 117.512 212.865 114.696 211.909 111.623L220.344 107.898C221.541 110.95 223.559 113.596 226.165 115.534C228.661 117.37 231.67 118.342 234.749 118.309C236.346 118.346 237.934 118.048 239.414 117.434C240.628 116.933 241.686 116.106 242.474 115.039C243.211 113.974 243.59 112.694 243.556 111.391C243.609 109.872 243.088 108.391 242.1 107.252C240.894 106.021 239.387 105.142 237.735 104.705L227.061 101.212C223.275 100.192 219.871 98.0496 217.283 95.0571C215.099 92.3394 213.935 88.9199 213.999 85.4093C213.942 82.375 214.76 79.3901 216.35 76.8232C217.964 74.2998 220.239 72.2865 222.918 71.0113C225.941 69.5706 229.247 68.855 232.584 68.9197C235.731 68.8843 238.853 69.4924 241.765 70.7079C244.468 71.8421 246.918 73.5218 248.967 75.6456C250.984 77.7512 252.525 80.2795 253.482 83.0541L245.123 86.8502C244.171 84.204 242.453 81.9135 240.197 80.2799C237.958 78.7193 235.297 77.9086 232.584 77.9608C231.048 77.9316 229.523 78.2294 228.106 78.835C226.899 79.3489 225.853 80.1896 225.083 81.2659C224.312 82.4409 223.935 83.8383 224.009 85.2492C224.083 86.6602 224.604 88.0088 225.493 89.0935C226.726 90.4333 228.304 91.3944 230.046 91.8678L240.272 95.1329C244.07 96.2093 247.513 98.3096 250.236 101.212C252.476 103.87 253.661 107.284 253.557 110.784C253.612 113.835 252.753 116.83 251.094 119.37C249.39 121.908 247.043 123.93 244.303 125.222C241.145 126.699 237.7 127.426 234.226 127.35Z" fill="#F7F6FF" />
            <path d="M276.323 127.351C273.458 127.422 270.629 126.686 268.15 125.223C265.849 123.821 264.005 121.759 262.85 119.296C261.578 116.515 260.952 113.473 261.022 110.406V85.1071H270.799V109.572C270.764 111.14 271.11 112.692 271.807 114.091C272.466 115.371 273.478 116.428 274.718 117.132C276.028 117.878 277.509 118.258 279.01 118.234C280.499 118.261 281.968 117.88 283.263 117.132C284.499 116.406 285.508 115.339 286.175 114.055C286.903 112.598 287.262 110.979 287.22 109.344V85.1071H296.998V126.437H287.742V118.31L288.563 119.751C287.724 122.155 286.07 124.177 283.898 125.451C281.583 126.756 278.969 127.411 276.323 127.351Z" fill="#F7F6FF" />
            <path d="M306.103 141.633V85.1071H315.283V93.31L314.387 91.2583C315.779 89.0324 317.74 87.2344 320.06 86.0571C322.636 84.7716 325.477 84.1324 328.345 84.193C332.001 84.1436 335.597 85.1453 338.72 87.083C341.781 88.9828 344.312 91.6505 346.071 94.8308C347.912 98.1692 348.852 101.944 348.795 105.772C348.84 109.594 347.915 113.363 346.108 116.713C344.393 119.925 341.869 122.613 338.794 124.501C335.614 126.427 331.97 127.414 328.27 127.351C325.473 127.381 322.706 126.769 320.172 125.563C317.788 124.443 315.759 122.668 314.313 120.437L315.88 118.461V141.633H306.103ZM327.151 118.234C329.248 118.271 331.314 117.718 333.122 116.637C334.854 115.582 336.262 114.056 337.189 112.231C338.189 110.232 338.689 108.014 338.645 105.772C338.692 103.543 338.191 101.336 337.189 99.3534C336.232 97.4966 334.786 95.9477 333.013 94.8803C331.24 93.813 329.21 93.2692 327.151 93.3101C325.122 93.2752 323.125 93.8238 321.389 94.8929C319.652 95.9619 318.248 97.508 317.336 99.3534C316.378 101.36 315.88 103.561 315.88 105.792C315.88 108.023 316.378 110.224 317.336 112.231C318.259 114.064 319.667 115.598 321.401 116.659C323.136 117.72 325.128 118.266 327.151 118.234Z" fill="#F7F6FF" />
            <path d="M375.516 127.349C371.666 127.438 367.867 126.438 364.543 124.459C361.449 122.58 358.92 119.874 357.229 116.636C355.469 113.27 354.57 109.507 354.616 105.695C354.544 101.838 355.457 98.0277 357.266 94.6377C358.973 91.4859 361.471 88.8521 364.506 87.0056C367.605 85.1186 371.158 84.1444 374.769 84.1914C377.645 84.1328 380.503 84.6763 383.165 85.7881C385.501 86.7964 387.6 88.2985 389.323 90.1949C391.009 92.0617 392.327 94.2407 393.205 96.6135C394.114 99.1046 394.569 101.744 394.548 104.401C394.548 105.112 394.51 105.846 394.436 106.605C394.38 107.278 394.242 107.942 394.025 108.581H362.677V100.984H388.502L383.875 104.553C384.338 102.486 384.222 100.329 383.539 98.326C382.917 96.5674 381.768 95.0523 380.254 93.995C378.632 92.8986 376.715 92.3408 374.769 92.3984C372.842 92.3655 370.948 92.9066 369.32 93.9551C367.678 95.0551 366.413 96.6483 365.701 98.5136C364.836 100.825 364.517 103.311 364.767 105.77C364.53 108.062 364.877 110.377 365.775 112.492C366.581 114.37 367.936 115.95 369.656 117.015C371.45 118.099 373.505 118.652 375.59 118.612C377.588 118.674 379.563 118.174 381.299 117.167C382.845 116.229 384.12 114.892 384.994 113.291L392.905 117.243C392.079 119.261 390.801 121.056 389.173 122.484C387.409 124.048 385.37 125.26 383.165 126.056C380.71 126.943 378.12 127.381 375.516 127.349Z" fill="#F7F6FF" />
            <path d="M401.937 126.435V85.1052H411.117V94.2979L410.371 92.9288C411.002 90.2844 412.587 87.9785 414.812 86.4703C417.031 85.2027 419.546 84.5722 422.089 84.6461H424.478V93.4597H420.969C419.733 93.4033 418.498 93.607 417.342 94.0583C416.187 94.5095 415.135 95.1985 414.252 96.0822C413.37 97.07 412.69 98.2277 412.254 99.4871C411.818 100.747 411.635 102.082 411.714 103.415V126.435H401.937Z" fill="#F7F6FF" />
            <path d="M431.866 126.439V69.8367H450.974C454.283 69.7629 457.56 70.5051 460.527 72.0002C463.18 73.3664 465.394 75.4756 466.909 78.0795C468.465 80.8394 469.252 83.9791 469.185 87.1605C469.255 90.3298 468.468 93.4576 466.909 96.2017C465.393 98.7813 463.194 100.874 460.564 102.241C457.588 103.746 454.296 104.489 450.974 104.405H437.837V126.439H431.866ZM437.837 98.7084H451.272C453.442 98.7591 455.593 98.2772 457.542 97.3034C459.292 96.4054 460.745 95.0052 461.722 93.2758C462.752 91.3841 463.267 89.2468 463.214 87.0847C463.271 84.9335 462.755 82.8067 461.722 80.9295C460.736 79.217 459.284 77.8319 457.542 76.9419C455.593 75.9667 453.442 75.4834 451.272 75.5328H437.837V98.7084Z" fill="#F7F6FF" />
            <path d="M495.757 127.349C492.198 127.396 488.697 126.435 485.643 124.574C482.635 122.729 480.173 120.09 478.515 116.938C476.739 113.552 475.84 109.76 475.904 105.921C475.83 102.128 476.703 98.377 478.441 95.0201C480.048 91.9453 482.431 89.3619 485.345 87.5357C488.298 85.7001 491.701 84.7522 495.16 84.8014C497.823 84.7528 500.462 85.3106 502.886 86.434C505.092 87.4808 507.061 88.9813 508.67 90.8408C511.84 94.533 513.59 99.2691 513.595 104.173C513.595 104.68 513.57 105.251 513.521 105.885C513.47 106.516 513.395 107.163 513.297 107.821H479.486V102.504H510.087L507.401 104.784C507.881 102.251 507.554 99.6294 506.468 97.2993C505.446 95.1515 503.856 93.3355 501.878 92.0544C499.87 90.7597 497.536 90.0871 495.16 90.1184C492.722 90.1039 490.329 90.7887 488.256 92.0943C486.129 93.4238 484.41 95.3317 483.292 97.6028C482.053 100.209 481.561 103.119 481.874 105.997C481.564 108.912 482.096 111.856 483.404 114.468C484.595 116.792 486.406 118.728 488.629 120.052C490.785 121.355 493.249 122.038 495.757 122.028C498.378 122.122 500.962 121.378 503.147 119.9C505.119 118.503 506.774 116.689 507.997 114.583L512.775 117.166C511.908 119.088 510.663 120.808 509.117 122.219C507.401 123.812 505.416 125.074 503.258 125.944C500.871 126.902 498.323 127.38 495.757 127.349Z" fill="#F7F6FF" />
            <path d="M523.446 126.435V85.7116H529.044V93.6151L527.775 93.3077C528.71 90.7534 530.423 88.571 532.665 87.0807C535.007 85.5371 537.75 84.7432 540.539 84.8015C543.215 84.7608 545.855 85.441 548.19 86.7733C550.424 88.0647 552.28 89.9402 553.564 92.206C554.898 94.5741 555.581 97.2641 555.542 99.9938V126.435H549.942V102.197C550.007 100.02 549.56 97.8591 548.637 95.8943C547.822 94.232 546.552 92.8456 544.98 91.9026C543.334 90.9465 541.464 90.461 539.569 90.4975C537.662 90.4639 535.781 90.9489 534.12 91.9026C532.519 92.8481 531.222 94.2475 530.389 95.9302C529.448 97.8773 528.987 100.028 529.044 102.197V126.435H523.446Z" fill="#F7F6FF" />
            <path d="M94.7551 48.7223C94.0953 48.7223 93.4456 48.8878 92.8636 49.2042C92.2816 49.5206 91.7851 49.9781 91.4181 50.5363L74.8786 75.6916C74.4377 76.3623 74.2016 77.1508 74.2002 77.9578C74.1988 78.7648 74.432 79.5542 74.8706 80.2265L128.056 161.748C128.186 161.947 128.38 162.094 128.604 162.162C128.829 162.231 129.07 162.217 129.286 162.123C129.502 162.03 129.679 161.862 129.786 161.649C129.893 161.437 129.923 161.193 129.872 160.96L115.065 93.577C114.946 93.0342 114.937 92.4724 115.038 91.9259C115.139 91.3794 115.349 90.8598 115.655 90.3987L136.287 59.2656C136.47 58.9896 136.716 58.7632 137.005 58.6062C137.293 58.4492 137.615 58.3663 137.942 58.3648C138.269 58.3633 138.592 58.4431 138.882 58.5974C139.172 58.7517 139.42 58.9758 139.605 59.2502L160.461 90.1073C160.775 90.5718 160.991 91.0973 161.096 91.6509C161.2 92.2045 161.191 92.7742 161.07 93.3242L153.148 129.05C153.096 129.283 153.126 129.526 153.233 129.739C153.339 129.952 153.516 130.12 153.731 130.214C153.947 130.308 154.188 130.322 154.413 130.254C154.638 130.186 154.832 130.04 154.962 129.842L187.454 80.2281C187.895 79.5556 188.129 78.7653 188.129 77.9571C188.128 77.1489 187.892 76.359 187.45 75.6873L170.914 50.5362C170.547 49.9781 170.05 49.5206 169.468 49.2042C168.886 48.8878 168.237 48.7223 167.577 48.7223H94.7551Z" fill="#F7F6FF" />
            <path d="M136.622 88.6485L137.63 84.8395L138.637 88.6485C138.973 89.917 139.63 91.0736 140.542 92.0025C141.455 92.9314 142.591 93.6001 143.837 93.9416L147.579 94.9672L143.837 95.9928C142.591 96.3343 141.455 97.003 140.542 97.9319C139.63 98.8608 138.973 100.017 138.637 101.286L137.63 105.095L136.622 101.286C136.287 100.017 135.63 98.8608 134.717 97.9319C133.805 97.003 132.669 96.3343 131.423 95.9928L127.681 94.9672L131.423 93.9416C132.669 93.6001 133.805 92.9314 134.717 92.0025C135.63 91.0736 136.287 89.917 136.622 88.6485Z" fill="#F7F6FF" />
            <defs>
                <filter id="filter0_f_38_8008" x="0.649826" y="0.861374" width="638.487" height="204.77" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur stdDeviation="29.6053" result="effect1_foregroundBlur_38_8008" />
                </filter>
            </defs>
        </svg>

    )
}

const PlayIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className='w-[40px] md:w-[75px]  transition-all hover:scale-110' viewBox="0 0 75 75" fill="none">
            <circle cx="37.4958" cy="37.6843" r="37.0066" fill="#F7F6FF" />
            <path d="M52.9717 36.0265C54.766 37.0624 54.766 39.6522 52.9717 40.6881L32.7863 52.3422C30.992 53.3781 28.7492 52.0832 28.7492 50.0113L28.7492 26.7032C28.7492 24.6314 30.992 23.3365 32.7863 24.3724L52.9717 36.0265Z" fill="url(#paint0_linear_38_8020)" />
            <defs>
                <linearGradient id="paint0_linear_38_8020" x1="16.0427" y1="27.5727" x2="21.7361" y2="58.2786" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#5F45FD" />
                    <stop offset="0.709584" stopColor="#FDA2F5" />
                </linearGradient>
            </defs>
        </svg>
    )
}


const VideoComponent = () => {
    const [clicked, setClicked] = useState(false);

    const handleClicked = () => {
        setClicked(true);
    };

    return (
        <div className="w-full cursor-pointer relative h-[189px] md:h-[640px] rounded-[20px] overflow-hidden">
            {!clicked && (
                <div onClick={handleClicked} className="w-full flex items-center justify-center h-full">
                    <img src={VideoPlaceHolderImage} className="w-full h-full absolute" alt="video-placeholder" />
                    <div className="relative z-10 flex flex-col items-center justify-center">
                        <LogoIcon />
                        <PlayIcon />
                        <div className="rounded-[111px] md:mb-[90px] mt-3 md:mt-10 bg-white px-5 py-2">
                            <p className="text-subTextBold md:text-subHeadingTwoBold font-semibold text-transparent bg-clip-text bg-hover-gradient-1">How SuperPen works</p>
                        </div>
                    </div>
                </div>
            )}
            {clicked && (
                <iframe
                    className="w-full h-full"
                    src="https://www.youtube.com/embed/wTM6HdML-Vs?si=z3rA1yxRZSs0EOQI&autoplay=1&rel=0"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerPolicy="strict-origin-when-cross-origin"
                    allowFullScreen
                ></iframe>
            )}
        </div>
    );
};



const HomeHero = () => {
    // const { isMobile } = useResponsive();
    return (
        <div className="flex flex-col items-center justify-center mt-[33px] md:mt-[105px] md:p-0 p-2">
            <h1 className="text-headingOne md:text-headingBigOne text-space-dark text-center">
                <span className="flex items-center justify-center flex-col md:flex-row md:flex-wrap gap-2 md:gap-4">
                    Create viral  <LinkedinIconWithText /> content
                </span>
                <span className="text-star-default">in minutes</span>
            </h1>
            <h2 className="mt-4 text-center text-bodyTwo md:text-subHeadingThree text-gray-5">
            Here’s the secret: The smartest LinkedIn creators <b>repurpose already viral posts</b>
            </h2>
            <a className="filled-btn bg-space-default mt-6 md:large md:mt-8" href={links.sigup} target="_blank">
                Get started for <span className='text-astro-default'>FREE</span>
            </a>
            <p className='text-bodyOne mt-2 mb-2.5 text-star-light'>No credit card required</p>
            {/* <p className='text-bodyTwo md:text-subHeadingThree text-gray-4  mb-10 text-center'>Here’s the secret: The smartest LinkedIn creators <b>repurpose already viral posts</b> </p> */}
            <div className='mt-[20px]'>
                <img src={GroupAvatarImage} className='h-[40px]' alt="group-img" />
            </div>
            <p className="text-bodyThree md:text-subHeadingThree text-star-light text-center mt-2">
                Trusted by 1,000+ top creators, founders, freelancers & writers worldwide.
            </p>
            <div className='my-14 container'>
                <VideoComponent />
            </div>
            <div className="mt-6 md:mt-16 md:container pb-[68px] md:pb-[164px]">
                <img src={WebsiteScreenShotImage} alt="website-dummy" />
            </div>
            <script src="https://player.vimeo.com/api/player.js"></script>
        </div>
    )
}

export default HomeHero;